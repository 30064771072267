import React from "react"
import styled from "styled-components"

import { TextGroup, Media, Cta } from "components/common"

import { gradientBorder, device } from "utils"

const ContactOption = ({ icon, title, hat, subtitle, ctas, vpId }) => {
  return (
    <StyledContactOption>
      <div className="top">
        <div className="text_container">
          <TextGroup
            hat={hat}
            hatAs="h3"
            title={title}
            titleAs="p"
            cta={() => <Cta ctas={ctas} />}
          />
        </div>
        <div className="media_container">
          <Media media={{ desktop: icon }} />
        </div>
      </div>
      {subtitle && (
        <div className="details">
          <TextGroup subtitle={subtitle} subtitleAs="pSmall" />
        </div>
      )}
    </StyledContactOption>
  )
}

const StyledContactOption = styled.li`
  display: grid;
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);
  grid-template-columns: 1fr;

  ${gradientBorder({
    background: "var(--card-shade_1)",
    gradient: "var(--card-border)",
  })};

  .top {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    @media ${device.laptop} {
      grid-template-columns: 2fr 1fr;
    }

    .hat {
      text-transform: none;
    }

    .title {
      a {
        font-size: 0.875rem;

        @media ${device.laptop} {
          font-size: 1.25rem;
        }
      }
    }

    .cta {
      margin-top: var(--sp-8);
      --button-gradient-border-color: var(--opero-gradient);
    }

    .media_container {
      text-align: center;

      @media ${device.laptop} {
        text-align: right;
      }
    }
  }

  .text_container,
  .details,
  .media_container {
    padding: var(--sp-32);
    position: relative;

    @media ${device.laptop} {
      padding: var(--sp-48);
    }
  }

  .details {
    border-top: 1px solid rgba(67, 69, 74, 1);
    --tg-subtitle-color: var(--highlight_3);

    ul {
      list-style-type: disc;
    }
  }
`

export default ContactOption
