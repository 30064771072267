import React from "react"
import styled from "styled-components"
import { VSection, Grid } from "components/common"

import ContactOption from "./ContactOption"

const ContactOptions = ({ title, subtitle, valueProps }) => {
  const renderOptions = () => (
    <Grid list={valueProps} component={(vp) => <ContactOption {...vp} />} />
  )

  return (
    <StyledContactOptions>
      <VSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        subtitleAs="pSmall"
        grid={renderOptions}
      />
    </StyledContactOptions>
  )
}

const StyledContactOptions = styled.div`
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);
  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
  }

  .v_gridContainer {
    --grid-col: 1;
    --grid-col-max: 825px;
    --grid-align: center;
  }
`

export default ContactOptions
