import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { useContentfulSection, useMeta } from "utils"

import { Layout, Seo } from "components/common"

import { PrimaryNav, MobileNav } from "src/components/navigation"

import ContactOptions from "src/sections/contact/ContactOptions.js"

const ContactPage = ({ data: { contact } }) => {
  const meta = useMeta(contact)
  const { contact_options } = useContentfulSection(contact.sections)

  return (
    <Layout>
      <Seo {...meta} />
      <MobileNav />
      <PrimaryNav />

      <ContactWrap>
        <ContactOptions {...contact_options} />
      </ContactWrap>
    </Layout>
  )
}

const ContactWrap = styled.div``

export const query = graphql`
  {
    contact: contentfulPage(pageId: { eq: "contact" }) {
      ...Page
    }
  }
`

export default ContactPage
